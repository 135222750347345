'use strict';

import settings from '../settings';
import { HttpContext } from '../utils/httpContext';

class UzenetService {
  async SendMessage({ form }) {
    const url = settings.baseUrl + 'Api/Uzenet/SendMessage';
    const result = await HttpContext.post({ url, data: form });
    return result;
  }

  async GetMessages() {
    const url = settings.baseUrl + 'Api/Uzenet/GetMessages';
    const result = await HttpContext.post({ url });
    return result;
  }

  async GetEmailServiceStatus(uzenetId) {
    const url = settings.baseUrl + 'Api/Uzenet/GetEmailServiceStatus';
    const result = await HttpContext.post({ url, data: { uzenetId } });
    return result;
  }

  async GetCurrentQueueStatus() {
    const url = settings.baseUrl + 'Api/Uzenet/GetCurrentQueueStatus';
    const result = await HttpContext.post({ url });
    return result;
  }

  
}

let instance = new UzenetService();
export { instance as uzenetService };
