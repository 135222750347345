import { ref, computed } from '@vue/composition-api';
import { ModalFunctions } from '../functions/ModalFunctions';
import store from '../store';
import { ModalStoreTypes } from '../store/modules/modal';
import { IsVueFormValid } from './vueUtils';

export const useBaseModal = function(props, context) {
  let formSaving = ref(false);
  let buttonsDisabled = ref(false);
  let isButtonsDisabled = computed(s => {
    return formSaving.value || buttonsDisabled.value;
  });
  let modalInfo = computed(() => {
    return store.getters[ModalStoreTypes.getters.getModalByName](
      props.modalName,
      props.idx
    );
  });
  let Close = function() {
    buttonsDisabled.value = true;
    return ModalFunctions.CloseModal(props.modalName, props.idx);
  };
  let SetLoading = function(isLoading = true) {
    return ModalFunctions.SetLoading(props.modalName, props.idx, isLoading);
  };

  return {
    SetLoading,
    Close,
    modalInfo,
    formSaving,
    isButtonsDisabled,
  };
};
export const useModalHelper = function(props, context) {
  let formId = computed(() => {
    return 'form_' + props.modal.idx;
  });

  return {
    formId,
    IsVueFormValid,
  };
};
