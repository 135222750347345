<template>
  <b-form @submit.prevent="OnSubmit">
    <b-form-group label="Címzett csoportok:">
      <!-- v-if="isSzerkeszto">-->
      <k-basic-multi-select
        v-model="$v.form.CimzettCsoportok.$model"
        :validator="$v.form.CimzettCsoportok"
        :options="felhasznCimkek"
        multiple
        labelBy="Text"
      ></k-basic-multi-select>
      <b-form-text id="input-live-help">Azok a felhasználók kapják, akikre a kiválasztott cimkék igazak</b-form-text>
    </b-form-group>
    <b-form-group label="Címzett felhasználók:">
      <k-user-valaszto
        class="big-dropdown"
        v-model="$v.form.CimzettFelhasznalok.$model"
        :validator="$v.form.CimzettFelhasznalok"
        :clear-on-select="true"
        :close-on-select="true"
      ></k-user-valaszto>
    </b-form-group>
    <b-form-group label="Címzett szervezetek">
      <!-- v-if="isSzerkeszto">-->
      <k-basic-multi-select
        v-model="$v.form.CimzettSzervezetek.$model"
        :validator="$v.form.CimzettSzervezetek"
        :options="szervezetekFiltered"
        multiple
        labelBy="Text"
      ></k-basic-multi-select>
      <!-- <k-szervezet-valaszto
        v-model="form.CimzettSzervezetek"
      ></k-szervezet-valaszto> -->
      <b-form-text id="input-live-help"
        >Minden a szervezethez tartozó személy kapni fog az
        üzenetből</b-form-text
      >
    </b-form-group>
    
    <b-form-group :label="!form.Targya?'':'Tárgy'">
      <k-basic-text-input
        v-model="$v.form.Targya.$model"
        placeholder="Tárgy"
        autocomplete="off"
        :validator="$v.form.Targya"
      ></k-basic-text-input>
    </b-form-group>
    <b-form-group :label="!form.Tartalma?'':'Üzenet'">
      <k-basic-ckeditor
          class="ck-rounded-corners ck-focused"
          v-model="$v.form.Tartalma.$model"
          :validator="$v.form.Tartalma"
          placeholder="Üzenet"
          minHeight="200px"
          required
        ></k-basic-ckeditor>
    </b-form-group>
    <div class="p-2 card mb-2">
        <b-form-group :label="!form.BelsoLink?'':'Belső link'">
          <b-form-input v-model="$v.form.BelsoLink.$model"
                        :validator="$v.form.BelsoLink"
                        placeholder="Belső link"
                        autocomplete="off"></b-form-input>
        </b-form-group>
        <div class="text-center font-size-xs mb-3 text-black-50">vagy</div>
        <b-form-group :label="!form.KulsoLink?'':'Külső link'">
          <b-form-input v-model="$v.form.KulsoLink.$model"
                        :validator="$v.form.KulsoLink"
                        placeholder="Külső link"
                        autocomplete="off"></b-form-input>
        </b-form-group>
    </div>
    <b-form-group label="Küldés módja:">
      <k-basic-multi-select
        v-model="$v.form.KuldesModja.$model"
        :options="kuldesModja"
        multiple
        labelBy="Text"
        :validator="$v.form.KuldesModja"
      ></k-basic-multi-select>
      <b-form-text id="input-live-help"></b-form-text>
    </b-form-group>
    <k-button
      type="submit"
      variant="primary"
      label="Mehet"
      icon="fa-paper-plane"
      :loading="sendMessageLoading"
    ></k-button>
  </b-form>
</template>

<script>
import { mapGetters } from 'vuex';
import required from 'vuelidate/src/validators/required';
import { NotificationFunctions } from '../../functions/NotificationFunctions';
import cloneDeep from 'lodash/cloneDeep';
import { AppStoreTypes } from '../../store/modules/app';
import { uzenetService } from '../../services/UzenetService';
import moment from 'moment';
import { AppFunctions } from '../../functions/AppFunctions';
import { Felho } from '../../enums/Felho';
import { appService } from '../../services/AppService';
import { useModalHelper } from '../../utils/modal';

let getDefaultForm = () => {
  return {
    Targya: null,
    Tartalma: '',
    CimzettSzervezetek: [],
    CimzettFelhasznalok: [],
    CimzettCsoportok: [],
    BelsoLink: null,
    KulsoLink: null,
    KuldesModja: [],
    ArhivnakMinosulesDatuma: moment()
      .clone()
      .add({ week: 1 })
      .startOf('day')
      .toISOString(),
  };
};

export default {
  name: 'send-message',
  data() {
    return {
      felhasznCimkek: [],
      sendMessageLoading: false,
      form: cloneDeep(getDefaultForm()),
      minDate: moment()
        .clone()

        .startOf('day')
        .toISOString(),
    };
  },
  validations() {
    let validations = {
      form: {
        CimzettCsoportok: {},
        CimzettSzervezetek: {},
        CimzettFelhasznalok: {},
        Targya: { required },
        Tartalma: { required },
        BelsoLink: {},
        KulsoLink: {},
        KuldesModja: { required }
      },
    };
    return validations;
  },
  setup(props, context) {
    let { formId, IsVueFormValid } = useModalHelper(props, context);
    return { formId, IsVueFormValid };
  },
  mounted() {
    this.LoadForm();
  },
  created() {},
  methods: {
    OnSubmit() {
      this.SendMessage();
    },
    async LoadForm() {
      this.$emit('set-loading', true);
      try {
        this.felhasznCimkek = await appService.GetCimkekByFelhoId({
          felhoId: Felho.FelhasznaloiCimkek,
          projektId: null
        });
      } catch (error) {
        NotificationFunctions.AjaxError({ error });
        this.Close();
      }
      this.$emit('set-loading', false);
    },
    async SendMessage() {
      if (!this.IsVueFormValid(this)) {
        console.log(this.$v);
        return;
      }
      this.sendMessageLoading = true;
      try {
        let data = {
          ...this.form,
          // CimzettFelhasznalok: this.selectedUsers.map(m => {
          //   return { Id: m.id, Text: m.text };
          // }),
        };
        await uzenetService.SendMessage({ form: data });
        this.form = cloneDeep(getDefaultForm());
        // this.$emit('update:selectedUsers', []);
        NotificationFunctions.SuccessAlert(
          'Új üzenet',
          'Az üzenet küldése megtörtént'
        );
      } catch (error) {
        NotificationFunctions.AjaxError({ error });
      }
      this.sendMessageLoading = false;
    },
    // RemoveUser(user) {
    //   this.$emit(
    //     'update:selectedUsers',
    //     this.selectedUsers.filter(f => f.id != user.id)
    //   );
    // },
  },
  computed: {
    ...mapGetters({
      szervezetek: AppStoreTypes.szervezetek.getAll,
      user: AppStoreTypes.user.get,
    }),
    szervezetekFiltered() {
      if (this.isSzerkeszto) {
        return this.szervezetek;
      }
      return this.szervezetek.filter(f => f.Id == this.user?.Szervezet?.Id);
    },
    isSzerkeszto() {
      return AppFunctions.IsSzerkeszto(this.user);
    },
    kuldesModja() {
      return [
        { Id: 1, Text: 'E-mail' },
        { Id: 2, Text: 'SMS' },
        { Id: 3, Text: 'Push notification' },
      ];
    }
  },
  watch: {},
  props: {
    selectedUsers: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
};
</script>

<style itemscope>
  .ck-editor__editable {
    min-height: 200px !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
  }
</style>